import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Module } from 'src/app/models/module.model';
import {  UserMeOutput } from 'src/app/models/users.model';
// import { ClientUserOutput} from '../../models/client.model';


export const genericActions = createActionGroup({
  source: 'Generic',
  events:{
    'Loading start': emptyProps(),
    'Loading end': emptyProps(),

    'Loading Points': emptyProps(),
    'Loaded Points': props<{ myPoints: number }>(),
    'Loading Points Failed': emptyProps(),
    // 'Loading Client': emptyProps(),
    // 'Load Client Completed': props<{ client: ClientUserOutput | undefined }>(),
    // 'Load Client Failed': emptyProps(),
    'Loading Modules': emptyProps(),
    'Loading Modules Completed': props<{ modules: Module[] }>(),
    'Loading Modules Failed': emptyProps(),

  }
});




