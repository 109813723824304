import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { RestService } from './rest.service';
import { Client, ClientModuleDto } from '../../models/client.model';
import { NotificationService } from './notification.service';
import { Restrictions } from '../../models/restrictions.model';
import { Url } from '../infrastructure/url';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientPublicInfoDto } from 'src/app/models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private clientsUrlBackend = Url.UrlConcatenating(
    environment.apiUrl,
    'clients'
  );
  constructor(
    private rest: RestService,
    private notificationService: NotificationService,
    private httpClient: HttpClient
  ) {}

  loadClientByCode(code: string): Observable<ClientPublicInfoDto> {
    if (code === 'localhost' || code === 'benefits') {
      code = 'movesion';
      //code = 'edenred'; //TODO: This is only for dev
    }
    return this.rest
      .get$<ClientPublicInfoDto>(`public/byCode/${code}`, 'client')

      .pipe();
  }

  loadMyModules(): Observable<ClientModuleDto[]> {
    return this.rest.get$<ClientModuleDto[]>('my/modules', 'client');
  }

  // getClientInfoExtra(code: string): Observable<InfoCompany>{
  //   if (code === 'localhost' || code === 'benefits') {
  //     code = 'movesion';
  //  }
  //  return this.httpClient
  //  .get<InfoCompany>(
  //   Url.UrlConcatenating(this.clientsUrlBackend, 'public', 'byCode', code)
  //  )
  // }

  // getClientInfo(): Observable<ClientUserOutput> {
  //   return this.rest.get$<ClientUserOutput>('user/my',  'client').pipe(first());
  // }

  // getClientFullInfo(clientCode: string): Observable<Client>{
  //   return this.rest.get$<Client>(`public/byCode/${clientCode}`, 'client');
  // }

  setMyLogo(selectedFile: File): Observable<{ url: string }> {
    const fd = new FormData();
    fd.append('logo', selectedFile, selectedFile.name);

    return this.rest.post$<{ url: string }>('my/logo', fd, 'client');
  }

  setMyPdfAwardsFile(selectedFile: File): Observable<{ url: string }> {
    const fd = new FormData();
    fd.append('awards_regulation', selectedFile, selectedFile.name);
    return this.rest.post$<{ url: string }>(
      'my/awards-regulation',
      fd,
      'client'
    );
  }

  saveRestrictions(restrictions: Restrictions): Observable<string> {
    return this.rest
      .putEmptyResponse$('my', { restrictions: restrictions }, 'client')
      .pipe(
        first(),
        tap(() => {
          this.notificationService.showSuccess('Restrizioni aggiornate!');
        })
      );
  }

  contactEmailChanged(email: string): Observable<string> {
    return this.rest.putEmptyResponse$('my', { email }, 'client').pipe(
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }
}
