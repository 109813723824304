import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsUserAuthorized } from './shared/services/isUserAuthorized.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [IsUserAuthorized],
    pathMatch: 'prefix',

  },
  {
    path: 'auth/login',
    loadComponent: () =>
      import('./authentication/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
