import { generalReducer, GeneralState } from './general/general.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { authReducer, AuthState } from './auth/auth.reducer';

export interface AppState {
  generalState: GeneralState;
  authState: AuthState;
}


export const appReducers: ActionReducerMap<AppState> = {
  generalState: generalReducer,
  authState: authReducer,
};
