import { environment } from 'src/environments/environment';

export class Url {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static UrlConcatenating(url1: string, ...params: string[]): string {
    let urlReturn = '';
    if (url1.endsWith('/')) {
      urlReturn = url1.substr(0, url1.length - 1);
    } else {
      urlReturn = url1;
    }

    params.forEach((p) => {
      if (p.endsWith('/')) {
        p = p.substr(0, p.length - 1);
      }
      if (p === '') {
      } else {
        if (!p.startsWith('/')) {
          urlReturn += '/' + p;
        } else {
          urlReturn += p;
        }
      }
    });

    return urlReturn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendSharing(): string {
    return this.UrlConcatenating(environment.apiUrl, 'sharing');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendClients(): string {
    return this.UrlConcatenating(environment.apiUrl, 'clients');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendSworking(): string {
    return this.UrlConcatenating(environment.apiUrl, 'gw1/sworking');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendAwards(): string {
    return this.UrlConcatenating(environment.apiUrl, 'awards');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendPooling(): string {
    return this.UrlConcatenating(environment.apiUrl, 'mpooling');
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendSurvey(): string {
    return this.UrlConcatenating(environment.apiUrl, 'survey');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendBenefits(): string {
    return this.UrlConcatenating(environment.apiUrl, 'gw1/benefits');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendUsers(): string {
    return this.UrlConcatenating(environment.apiUrl, 'users');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendIdam(): string {
    return this.UrlConcatenating(environment.apiUrl, 'idam');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static BackendPolicy(): string {
    return this.UrlConcatenating(environment.apiUrl, 'gw2/policy');
  }

  public static BackendVoucherHandler(): string {
    return this.UrlConcatenating(environment.apiUrl, 'gw2/voucher-handler');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static GetBackend(backendType: BackendType): string {
    switch (backendType) {
      case 'sharing':
        return Url.BackendSharing();
      case 'user':
        return Url.BackendUsers();
      case 'idam':
        return Url.BackendIdam();
      case 'client':
        return Url.BackendClients();
      case 'sworking':
        return Url.BackendSworking();
      case 'awards':
        return Url.BackendAwards();
      case 'pooling':
        return Url.BackendPooling();
      case 'survey':
        return Url.BackendSurvey();
      case 'benefits':
        return Url.BackendBenefits();
      case 'policy':
        return Url.BackendPolicy();
      case 'voucher-handler':
        return Url.BackendVoucherHandler();
      case 'Unknown':
        return environment.apiUrl;
      default:
        return environment.apiUrl;
    }
  }
}

export type BackendType =
  | 'Unknown'
  | 'sharing'
  | 'user'
  | 'idam'
  | 'client'
  | 'sworking'
  | 'awards'
  | 'pooling'
  | 'survey'
  | 'benefits'
  | 'policy'
  | 'voucher-handler';
