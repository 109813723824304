import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ProfileService } from 'src/app/shared/services/profile.service';

import { AwardService } from '../../shared/services/award.service';
import { ClientsService } from '../../shared/services/clients.service';
import { profileActions } from './profile.actions';

@Injectable()
export class ProfileEffects {
  loadingProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(profileActions.loadingProfile),
        mergeMap(() => this.profileService.getMyProfile()),
        switchMap((profile) => {
          if (profile) {
            return [profileActions.loadedProfile({ profile })];
          } else {
            return [profileActions.loadingProfileFailed()];
          }
        }),
        catchError(() => [profileActions.loadingProfileFailed()])
      ),
    { dispatch: true }
  );

  loadingEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.loadedProfile),
      mergeMap(
        () => this.profileService.getEmployeeInfo()
      ),
      switchMap((info) => {
        if (info) {
          return [profileActions.loadedEmployee({ employee: info })];
        } else {
          return [profileActions.loadEmployeeFailed()];
        }
      }),
      catchError(() => [profileActions.loadEmployeeFailed()]),
    )
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService
  ) {}
}
