import {createReducer, on} from '@ngrx/store';
import { genericActions } from './general.actions';

import { Module } from 'src/app/models/module.model';
import {InstallationInfoModel} from '../../models/installation-info-model';


export type Status = 'Initial' | 'Loading' | 'Ready' | 'Error';

export interface GeneralState {


  isLoading: boolean;
  // client: ClientUserOutput | undefined;
  myPoints: number | undefined;
  modules: Module[];
  installationInfo: InstallationInfoModel | undefined;
  status: Status;
}

export const initialState: GeneralState = {


  isLoading: false,
  // client: undefined,
  myPoints: undefined,
  modules: [],
  installationInfo: undefined,
  status: 'Initial',
};

export const generalReducer = createReducer(
  initialState,
  on(genericActions.loadingStart, (state) => ({...state, isLoading: true,})),
  on(genericActions.loadingEnd, (state) => ({ ...state, isLoading: false })),

  on(genericActions.loadingPoints, (state) => ({...state, isLoading: true})),
  on(genericActions.loadedPoints, (state, action) => ({...state, isLoading: false, myPoints: action.myPoints})),
  on(genericActions.loadingPointsFailed, (state) => ({...state, isLoading: false, myPoints: undefined})),



);




