import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Module } from 'src/app/models/module.model';
import { Office } from 'src/app/models/office.model';
import { Restrictions } from 'src/app/models/restrictions.model';
import {Client, ClientModuleDto, ClientPublicInfoDto} from "../../models/client.model";

export const companyActions = createActionGroup({
  source: 'Company',
  events: {
    'Load Company By Code': props<{ clientCode: string }>(),
    'Load Company By Code Success': props<{   client: ClientPublicInfoDto    }>(),
    'Load Company By Code Failed': emptyProps(),
    'Load Modules': emptyProps(),
    'Load Modules Success': props < { modules: ClientModuleDto[] }>(),
    'Load Modules Failes': emptyProps(),
  }
});


