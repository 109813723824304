import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  catchError,
  concatMap,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { IdamService } from '../../shared/services/idam.service';
import { profileActions } from '../profile/profile.actions';
import { authActions } from './auth.actions';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthEffects {
  checkToken$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.checkingTokens),
        switchMap(() => this._idamService.checkToken()),
        catchError((err) => {
          console.error(err);
          if (err.url.indexOf('refreshToken') > -1) {
            if (
              err.error.data.errors.some(
                (es: any) => es.indexOf('Not updated') > -1
              )
            ) {
              return of(
                authActions.authenticatingSuccess({
                  token: this._idamService.tokenValue,
                  refreshToken: this._idamService.refreshTokenValue,
                  forceRedirect: false,
                })
              );
            }
          }
          void this._router.navigate(['auth']);
          throw err;
        }),
        switchMap(
          (tokens: { token: string; refreshToken: string } | undefined) => {
            console.log('tokens', tokens);
            if (tokens && tokens.token && tokens.refreshToken) {
              return [
                authActions.authenticatingSuccess({
                  token: tokens.token,
                  refreshToken: tokens.refreshToken,
                  forceRedirect: false,
                }),
              ];
            } else {
              //void this._router.navigate(["auth"]);
              return [authActions.checkTokensFailed()];
            }
          }
        )
      ),
    { dispatch: true }
  );

  authenticatingEnd$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.authenticatingSuccess),
        tap((action) => {
          this._idamService.setToken(action.token, action.refreshToken);
          if (action.forceRedirect) {
            if (
              action.redirect_url !== undefined &&
              action.redirect_url !== ''
            ) {
              window.location.href = action.redirect_url
            } else {
              void this._router.navigate(['/home']);
            }
          }
        }),
        switchMap(() => [profileActions.loadingProfile()])
      ),
    { dispatch: true }
  );

  loggingOut$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.loggingOut),
        mergeMap(() => this._idamService.logout()),
        concatMap(() => {
          void this._router.navigate(['auth']);
          return of(authActions.logout());
        }),
        catchError(() => {
          void this._router.navigate(['auth']);
          return of(authActions.logoutFailed());
        })
      ),
    { dispatch: true }
  );

  authenticatingByRealSso$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.authenticatingByRealSSO),
        // mergeMap(() => this._idamService.logout()),
        concatMap(() => {
          window.location.href = `${environment.urlSsoSso}/?redirect_url=${window.location.href}`;
          return of(authActions.authenticatingByRealSSOSuccess());
        })
        // catchError(() => {
        //   void this._router.navigate(['auth']);
        //   return of(authActions.logoutFailed());
        // })
      ),
    { dispatch: true }
  );

  checkSsoConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.checkSsoConfig),
      concatMap((action) => this._idamService.clientSsoConfig(action.clientId)),
      switchMap((res) => {
        if (res.allowLogout) {
          localStorage.clear();
          window.location.href = environment.urlSsoLogout;
          return of(authActions.checkSsoConfigCompleted());
        } else {
          return of(authActions.loggingOut());
        }
      }),
      catchError(() => of(authActions.loggingOut()))
    )
  );

  constructor(
    private _actions$: Actions,
    private _idamService: IdamService,
    private _router: Router
  ) {}
}
