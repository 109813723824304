import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { AcceptedClientDocumentOutputDto, ClientDocumentOutputDto } from 'src/app/models/document.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private restService: RestService) {}

  getDocuments(clientId: string, moduleCode: string = 'benefits'): Observable<ClientDocumentOutputDto[]>{
    return this.restService.get$<ClientDocumentOutputDto[]>(`documents/actives?ClientId=${clientId}`, 'policy');
  }

  getNeededDocuments(moduleCode: string = 'benefits'): Observable<AcceptedClientDocumentOutputDto[]> {
    return this.restService.get$<AcceptedClientDocumentOutputDto[]>(`documents/me/needed?moduleCode=${moduleCode}`, 'policy').pipe(
     // catchError(err=> [])
    );
  }

  acceptPolicy(documentsIds: number[]): Observable<string> {
    return this.restService.post$('localized-documents/accept', documentsIds, 'policy')
      .pipe(
        map(() => 'accepted'),
        catchError((err) => {
          throw err;
        })
      );
  }
}
