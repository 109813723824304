import {createFeature, createReducer, createSelector, on} from '@ngrx/store';
import {UserMeOutput} from '../../models/users.model';
import {profileActions} from './profile.actions';
import {GeneralState} from '../general/general.reducer';
import {selectGeneralState} from '../general/general.selectors';

export type Status = 'Initial' | 'Loading' | 'Ready' |  'Error' ;

export const featureName = 'Profile';

export interface ProfileState {
  status: Status;
  profile: UserMeOutput | undefined;
}

export const initialState: ProfileState = {
  status: 'Initial',
  profile: undefined,
};
export const profileCreateFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,

    on(profileActions.loadingProfile, (state) => ({...state,  profile: undefined, status: 'Loading'})),
    on(profileActions.loadedProfile, (state, action) => ({
      ...state,
      profile: action.profile,
      status : 'Ready'
    })),
    on(profileActions.loadingProfileFailed, (state) => ({...state, profile: undefined, status: 'Error'})),

  ),
});


export const {
  selectProfile,
  selectStatus
} = profileCreateFeature;
//
// export const selectProfileMeOutput = createSelector( selectGeneralState, (state: GeneralState) => state.profile );
// export const selectName= createSelector( selectProfileMeOutput, (profile) => profile?.name );
// export const selectAvatar = createSelector(selectProfileMeOutput, (profile) => profile?.avatar?.url );
