import { Injectable } from '@angular/core';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { companyActions } from './actions';
import { of } from 'rxjs';
import { ClientsService } from 'src/app/shared/services/clients.service';
import { createFeature } from '@ngrx/store';

@Injectable()
export class CompanyEffects {
  loadClientByCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.loadCompanyByCode),
        mergeMap((prop) =>
          this.clientService.loadClientByCode(prop.clientCode).pipe(
            map((res) => {
              return companyActions.loadCompanyByCodeSuccess({
                client: res,
              });
            })
          )
        ),
        tap((res) => console.log(res)),
        catchError((err) => {
          return of(companyActions.loadCompanyByCodeFailed());
        })
      ),
    { dispatch: true }
  );
  loadMyModules$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(companyActions.loadModules),
        mergeMap(() =>
          this.clientService.loadMyModules().pipe(
            map((modules) =>
              companyActions.loadModulesSuccess({
                modules,
              })
            )
          )
        ),
        catchError((err) => {
          console.error(err);
          return of(companyActions.loadModulesFailes());
        })
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private clientService: ClientsService
  ) {}
}
