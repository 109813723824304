import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, retry, tap } from 'rxjs/operators';
import { CONSTANTS } from '../infrastructure/constants';
import { RestService } from './rest.service';
import { RefreshTokenResponse } from '../../models/token.model';
import { OAuth2ConfigOpenOutputDto } from 'src/app/models/sso.config.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class IdamService {
  constructor(private rest: RestService, private router: Router) {}

  get refreshTokenValue(): string {
    return localStorage.getItem(CONSTANTS.refreshToken) ?? '';
  }

  get tokenValue(): string {
    let token = localStorage.getItem(CONSTANTS.token) ?? '';
    token = token.split('?token=')[0];
    return token;
  }

  refreshToken(refreshToken: {
    token: string;
    refreshToken: string;
  }): Observable<{ token: string; refreshToken: string } | undefined> {
    return this.rest
      .post$<{ token: string; refreshToken: string } | undefined>(
        'refreshToken',
        refreshToken,
        'idam'
      )
      .pipe(
        retry(3),
        catchError((err) => of(undefined))
      );
  }

  createNewToken(token: string): Observable<RefreshTokenResponse | undefined> {
    return this.rest
      .post$<RefreshTokenResponse | undefined>('new-token', { token }, 'idam')
      .pipe(
        catchError((err) => {
          console.error('Errore nella creazione nuovo token', err);
          throw err;
        })
      );
  }

  isLogged(): boolean {
    const tokenValue = this.tokenValue;
    return tokenValue !== undefined && tokenValue !== null && tokenValue !== '';
  }

  logout(): Observable<boolean> {
    if (this.isLogged()) {
      return this.rest
        .post$<{ success: boolean }>('logout', undefined, 'idam')
        .pipe(
          tap(() => {
            localStorage.clear();
            void this.router.navigateByUrl('/auth/login');
          }),
          map((res: any) => res.success),
          catchError(() => {
            localStorage.clear();
            void this.router.navigateByUrl('/auth/login');
            return of(true);
          }),
          finalize(() => {
            localStorage.clear();
            void this.router.navigateByUrl('/auth/login');
          })
        );
    } else {
      localStorage.clear();
      void this.router.navigateByUrl('/auth/login');
      return of(true);
    }
  }

  setToken(token: string, refreshToken: string): void {
    localStorage.setItem(CONSTANTS.token, token);
    localStorage.setItem(CONSTANTS.refreshToken, refreshToken);
  }

  checkToken(): Observable<
    { token: string; refreshToken: string } | undefined
  > {
    if (
      this.tokenValue &&
      this.tokenValue !== '' &&
      this.refreshTokenValue !== ''
    ) {
      const helper = new JwtHelperService();
      if (!helper.isTokenExpired(this.tokenValue)) {
        console.log(
          'refreshing token at ',
          helper.getTokenExpirationDate(this.tokenValue)?.toLocaleString()
        );
        console.log(helper.decodeToken(this.tokenValue));

        return of({
          token: this.tokenValue,
          refreshToken: this.refreshTokenValue,
        });
      }
      console.log('is refreshing...');
      return this.refreshToken({
        token: this.tokenValue,
        refreshToken: this.refreshTokenValue,
      });
    }
    return of(undefined);
  }

  userNameExist(email: string): Observable<boolean> {
    return this.rest.get$<boolean>(`idam/email-exists/${email}`);
  }

  clientSsoConfig(clientId: string): Observable<OAuth2ConfigOpenOutputDto> {
    return this.rest.get$<OAuth2ConfigOpenOutputDto>(
      `idam/client/${clientId}/sso-config-open`
    );
  }
}
