import {AppState} from '../app.state';
import {createSelector} from '@ngrx/store';
import { AuthState } from './auth.reducer';

export const selectAuthState = (appState: AppState): AuthState => appState.authState;

export const selectIsAuthenticating = createSelector( selectAuthState, (state) => state.status === 'CHECKING' );

export const isAuthenticated = createSelector(selectAuthState, (state)=> state.status === 'AUTHENTICATED');

export const status = createSelector(selectAuthState, (state)=> state.status);
