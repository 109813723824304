export const environment = {
  production: false,
  googleMapKey: 'AIzaSyBVEl3-SWNKg2y_l510ncH5bIdNEm3hykI',
  stripeKey:
    'pk_test_51IQpmKFvMkoupxOajVrBFQhxxChF3q5aX2LZ9mNoiByi8qQCM7L0PmZ1tRIBvOZO9csNyamOeo7o5mSrqm0HzQov00lsM3WQwC',
  urlSsoSso: 'https://idam.dev.mobilitycompany.it/auth/welcome',
  urlSsoLogout: 'https://idam.dev.mobilitycompany.it/auth/logout',
  urlSsoRegister: 'https://idam.dev.mobilitycompany.it/auth/register',
  urlSsoRecovery: 'https://idam.dev.mobilitycompany.it/auth/password-recovery',
  apiUrl: 'https://api.dev.mobilitycompany.it',
  ambient: 'dev'
};
