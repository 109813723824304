import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AwardService {

  constructor(private _restService: RestService) {
  }

  getMyPoints(): Observable<{value: number}> {
    return this._restService.get$<{value: number}>(`points/my/available`,  'awards');
  }

  // getCurrentCampaign(): Observable<CampaignOutputModel> {
  //   return this._restService.get$<CampaignOutputModel>(`campaign/current`,  'awards');
  // }
  //
  // getCampaigns(): Observable<ReferenceCampaignOutput>{
  //   return this._restService.get$<ReferenceCampaignOutput>('campaigns/reference',  'awards');
  // }
  //
  // getRanking(campaignId: string): Observable<UserRankInClient> {
  //   return this._restService.get$<UserRankInClient>(`ranking/my/${campaignId}`,  'awards');
  // }
  //
  // getHistory(campaignId: string): Observable<UserPointOutput[]> {
  //   return this._restService.get$<UserPointOutput[]>(`points/history/my/${campaignId}`, 'awards');
  // }
  //
  // getMyRewards(campaignId: string): Observable<PurchaseMy[]>{
  //   return this._restService.get$<PurchaseMy[]>(`buys/my?campaign=${campaignId}`,  'awards');
  // }

}
