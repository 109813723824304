import { createFeature, createReducer, createSelector, MemoizedSelector, on} from '@ngrx/store';
import {initialState} from './state';
import { companyActions} from './actions';
import { Module } from 'src/app/models/module.model';
import { status } from '../auth/auth.selector';
import { ClientModuleDto } from 'src/app/models/client.model';

export const companyFeatureName = 'company';

export const companyCreateFeature = createFeature({
  name: 'company',
  reducer: createReducer(
    initialState,
    on(companyActions.loadCompanyByCode, (state) => ({ ...state })),
    on(companyActions.loadCompanyByCodeSuccess, (state, props) => ({ ...state, ...props })),
    on(companyActions.loadCompanyByCodeFailed, (state, props) => ({ ...state, status: 'Error', client: undefined, })),
    on(companyActions.loadModules, (state)=> ({...state})),
    on(companyActions.loadModulesSuccess, (state, props) => ({ ...state, modules: props.modules, status: 'Ready' })),
    on(companyActions.loadModulesFailes, (state)=>({...state, status: 'Error', modules:[]})),
  ),
 
});

// export const selectCompanyState: MemoizedSelector<object, CompanyState> = createFeatureSelector<CompanyState>(Names.NAME);

export const { selectClient, selectCompanyState, selectStatus, selectModules } = companyCreateFeature;
// export const selectClient = createSelector(
//   selectCompanyState,
//   (state: CompanyState) => state.client
// );


// export const selectHaveModule = (moduleCode: string) =>{
//   return createSelector(selectModules(), (modules) => modules?.find(m => m.code === moduleCode))
// };

export const selectHaveModule = (moduleCode: string): MemoizedSelector<Record<string, any> , ClientModuleDto|  undefined> => createSelector(selectModules, (modules) => modules?.find(m => m.code === moduleCode) );

// export const selectCompanyStatus = createSelector(selectCompanyState, (state: CompanyState) => state.status);


// export const companyReducer = (
//   state: CompanyState | undefined,
//   action: Action
// ): CompanyState => reducer(state, action);

