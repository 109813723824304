import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EmployeeDto } from 'src/app/models/employee.model';
import { Module } from 'src/app/models/module.model';
import {  UserMeOutput } from 'src/app/models/users.model';
// import { ClientUserOutput} from '../../models/client.model';


export const profileActions = createActionGroup({
  source: 'Profile',
  events:{

    'Loading Profile': emptyProps(),
    'Loaded Profile': props<{ profile: UserMeOutput }>(),
    'Loading Profile Failed': emptyProps(),

    'Load employee': emptyProps(),
    'Loaded employee': props<{employee: EmployeeDto}>(),
    'Load employee Failed': emptyProps()
  }
});




