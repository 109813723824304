import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    'Logging out': emptyProps(),
    Logout: emptyProps(),
    'Logout failed': emptyProps(),
    'Checking tokens': emptyProps(),
    'Authenticating Start': emptyProps(),
    'Authenticating End': props<{token: string; refreshToken: string; forceRedirect: boolean, redirect_url: string | undefined}>(),
    'Authenticating Success': props<{
      token: string;
      refreshToken: string;
      forceRedirect: boolean;
      redirect_url?: string;
    }>(),
    'Refresh Token End': props<{ token: string; refreshToken: string }>(),
    'Check tokens failed': emptyProps(),

    'Check sso config': props<{ clientId: string }>(),
    'Check sso config completed': emptyProps(),

    'Authenticating by RealSSO': emptyProps(),
    'Authenticating by RealSSO Success': emptyProps(),
  },
});

// export const loggingOut = createAction('[AUTH] Logging out');
// export const logOut = createAction('[AUTH] Logout');
// export const logOutFailed = createAction('[AUTH] Logout failed');

// export const authenticatingCheck = createAction('[AUTH] Checking tokens');
// export const authenticatingStart = createAction('[AUTH] Authenticating');
// export const authenticatingEnd = createAction('[AUTH] Authenticating Successfull', props<{ token: string; refreshToken: string; forceRedirect: boolean }>());
// export const authenticatingRefreshTokenEnd = createAction('[AUTH] Refresh token end', props<{ token: string; refreshToken: string }>());
// export const authenticatingCheckFailed = createAction('[AUTH] Checking tokens failed');
