import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/shared/services/profile.service';

import { genericActions } from './general.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { AwardService } from '../../shared/services/award.service';
import { ClientsService } from '../../shared/services/clients.service';
import { PolicyService } from 'src/app/shared/services/policy.service';
import { Router } from '@angular/router';

@Injectable()
export class GeneralEffects {


  loadingMyPoints$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(genericActions.loadingPoints),
        mergeMap(() => this._awardService.getMyPoints()),
        switchMap((points) => {
          if (points) {
            return [genericActions.loadedPoints({ myPoints: points.value })];
          } else {
            return [genericActions.loadingPointsFailed()];
          }
        }),
        catchError(() => [genericActions.loadingPointsFailed()])
      ),
    { dispatch: true }
  );



  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private clientService: ClientsService,
    private _awardService: AwardService,

  ) {}
}
