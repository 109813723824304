import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { IdamService } from './idam.service';
import { authActions } from 'src/app/store/auth/auth.actions';
import { selectClient } from 'src/app/store/company-store/reducer';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private idamService: IdamService,
    private router: Router,
    private _store: Store<AppState>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (
      req.urlWithParams.startsWith('/assets') ||
      req.url.endsWith('json') ||
      req.url.startsWith('https://maps.googleapis.com')
    ) {
      return next.handle(req);
    }
    let authReq = req.clone({ withCredentials: true });
    const token = this.idamService.tokenValue;
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(
      catchError((error) => {
        console.log('error on ' + authReq.urlWithParams, error, token);
        if (error.status === 0) {
          if (authReq.urlWithParams.indexOf('refreshToken') < 0) {
            return this.handle401Error(authReq, next);
          }

        }
        if (error.status === 413) {
          return throwError(error);
        }
        // eslint-disable-next-line eqeqeq
        if (error.status === 400 && error.error.data?.errorIndex == 1) {
          return;
        }
        if (error.status === 400 && (error?.error?.message || error?.message)) {
          return throwError(error);
        }
        if (
          error instanceof HttpErrorResponse &&
          this.isErrorTypeJwtExpired(error)
        ) {
          return this.handle401Error(authReq, next);
        }
        if (this.isRefreshTokenExpired(error)) {
          console.error('Refresh token expired...');
          localStorage.clear();
          void this.router.navigateByUrl('/login');
          return of(null);
        }
        return throwError(error);
      })
    );
  }

  private isErrorTypeJwtExpired(error: HttpErrorResponse): boolean {
    return (
      error.error.message === 'Authorizer: jwt expired' && error.status === 403
    );
  }

  private isRefreshTokenExpired(error: HttpErrorResponse): boolean {
    return error.error.code === 'refresh_token_expired' && error.status === 401; //|| error.status === 0;
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`),
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing$.value) {
      this.isRefreshing$.next(true);
      this.refreshTokenSubject.next(null);
      const token = this.idamService.tokenValue;
      const refreshToken = this.idamService.refreshTokenValue;
      if (token) {
        return this.idamService.refreshToken({ token, refreshToken }).pipe(
          switchMap((newToken) => {
            if (newToken == undefined) {
              return throwError(() => new Error('tokens not valid'));
            }
            this.idamService.setToken(newToken.token, newToken.refreshToken);
            this._store.dispatch(
              authActions.refreshTokenEnd({
                token: newToken.token,
                refreshToken: newToken.refreshToken,
              })
            );
            this.isRefreshing$.next(false);
            this.refreshTokenSubject.next(newToken.token);
            return next.handle(this.addTokenHeader(request, newToken.token));
          }),
          catchError((err) => {
            this.isRefreshing$.next(false);
            this.idamService.setToken('', '');
            this.logout();
            // this.idamService.logout();
            return throwError(() => err);
          })
        );
      }
    } else {
      return this.isRefreshing$.pipe(
        filter((result) => result === false),
        switchMap((result) => {
          if (!result) {
            return next.handle(
              this.addTokenHeader(request, this.idamService.tokenValue)
            );
          } else {
            throw new Error('Token not refreshed');
          }
        })
      );
    }
  }

  private logout(): void {
    this._store.select(selectClient).subscribe((client) => {
      if (client?.sso && client.real_sso) {
        this._store.dispatch(
          authActions.checkSsoConfig({ clientId: client._id })
        );
      } else {
        this._store.dispatch(authActions.loggingOut());
      }
    });
  }
}

// export const authInterceptorProviders = [
//   {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
// ];
