import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { AcceptedClientDocumentOutputDto, } from 'src/app/models/document.model';
import { policyDocumentsActions } from './policy.document.actions';

export const featureName = 'policyDocument';
export type Status = 'Initial' | 'Loading' | 'Ready' | 'Error';

export interface PolicyDocumentsState {


  documents: AcceptedClientDocumentOutputDto[];
  status: Status;
}

export const initialState: PolicyDocumentsState = {


  documents: [],
  status: 'Initial',
};


export const policyDocumentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(policyDocumentsActions.loadingDocuments, (state) => ({ ...state, status: 'Loading' })),
    on(policyDocumentsActions.loadingDocumentsCompleted, (state, action) => ({ ...state, isLoading: false, documents: action.documents, status: 'Ready' })),
    on(policyDocumentsActions.loadingDocumentsFailed, (state) => ({ ...state, isLoading: false, status: 'Error' })),
    on(policyDocumentsActions.acceptPolicyFailed, (state) => ({ ...state, isLoading: false, status: 'Error' })),

  ),
});



export const {
  selectDocuments,
  selectStatus,

} = policyDocumentFeature;



export const selectIsDocumentsLoading = createSelector(selectStatus, (status) => status === 'Loading');
