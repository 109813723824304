import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from './rest.service';
import { UserMeInput, UserMeOutput } from '../../models/users.model';
import { EmployeeDto } from 'src/app/models/employee.model';


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private rest: RestService) { }
  getMyProfile(): Observable<UserMeOutput> {
    return this.rest.get$<UserMeOutput>('me', 'user');
  }

  saveMyProfile(newUserInfo: UserMeInput): Observable<string> {
    return this.rest.putEmptyResponse$('me', { ...newUserInfo }, 'user');
  }


  getEmployeeInfo(): Observable<EmployeeDto>{
    return this.rest.get$<EmployeeDto>('employee/me', 'benefits');
  }

  acceptPolicy(): Observable<string>{
    return this.rest.postEmptyResponse$('employee/my/policies/accept', {}, 'benefits');
  }

  // saveMyAvatar(selectedFile: File): Observable<{ url?: string | undefined }> {
  //   const fd = new FormData();
  //   fd.append('avatar', selectedFile, selectedFile.name);
  //   return this.rest.post$<GenericResponse<{ url?: string | undefined }>>(
  //     'me/avatar',
  //     fd,
  //      'user'
  //   );
  // }

  // deleteMyAvatar(): Observable<string> {
  //   return this.rest.delete("users/me/avatar").pipe(switchMap(() => of("OK")));
  // }
}
