import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { companyActions } from './store/company-store/actions';

import { AppState } from './store/app.state';
import { authActions } from './store/auth/auth.actions';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(private store: Store<AppState>) {
    store.dispatch(authActions.checkingTokens());
    const hostNameSplitted = window.location.hostname.split('.');
    const clientCode = hostNameSplitted[0];

    this.store.dispatch(companyActions.loadCompanyByCode({ clientCode }));
  }
}
