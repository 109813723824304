import { Module } from 'src/app/models/module.model';
import { Office } from 'src/app/models/office.model';

import { ClientModuleDto, ClientPublicInfoDto} from '../../models/client.model';

export const initialState: CompanyState = {
  client: undefined,
  status: 'Initial',
  modules:[],
};


export interface CompanyState {
  client: ClientPublicInfoDto | undefined;
  modules: ClientModuleDto[];
  status: 'Initial' | 'Ready' | 'Error';
 // _id?: string;
 //  name?: string;
 //  code: string;
 //  email?: string;
 //  phone: string;
 //  link: string;
 //  sso?: boolean;
 //  offices?: Pick<Office, 'name' | 'address' | 'radius' | 'status' | 'id'>[];
 //  chosen_array_week_day?: number[];
 //  chosen_array_time?: number[];
 //  restrictions?: Restrictions;
 //  logo?: {
 //    base64: string;
 //    url: string;
 //  };
 //  sso_conf:{
 //    login_url_redirect: string;
 //  }
 //  awards_regulation: {
 //    url: string;
 //  };
 //  privacy_url: string;
 //  terms_conditions_url: string;
 //  modules?: Pick<Module, '_id' | 'name' | 'code'>[];
}



export interface State {
  company: CompanyState;
}
