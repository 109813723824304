import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { policyDocumentsActions } from './policy.document.actions';
import { PolicyService } from 'src/app/shared/services/policy.service';
import { Router } from '@angular/router';

@Injectable()
export class PolicyDocumentsEffects {


  loadingDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(policyDocumentsActions.loadingDocuments),
        mergeMap(() => this.policyService.getNeededDocuments()),
        map((documents) =>
          documents.sort((a, b) =>
            a.clientDocument.moduleCode == null &&
              b.clientDocument.moduleCode != null
              ? -1
              : 1
          )
        ),
        concatMap((res) => of(policyDocumentsActions.loadingDocumentsCompleted({ documents: res } ))),
        //  switchMap((res) => {
        //   // if (res) {
        //   //   return
        //   //     of(policyDocumentsActions.loadingDocumentsCompleted({ documents: res }));

        //   // } else {
        //   //   return of(policyDocumentsActions.loadingDocumentsFailed());
        //   // }
        //  }),
        catchError(() => of(policyDocumentsActions.loadingDocumentsFailed()))
      ),
    { dispatch: true }
  );

  acceptDocuments = createEffect(
    () =>
      this.actions$.pipe(
        ofType(policyDocumentsActions.acceptPolicyDocuments),
        mergeMap((action) =>
          this.policyService.acceptPolicy(action.documentsIds)
        ),
        switchMap(() => this.policyService.getNeededDocuments()),
        switchMap((res) => {
          if (res) {
            return [
              policyDocumentsActions.loadingDocumentsCompleted({ documents: res }),
            ];
          } else {
            return [policyDocumentsActions.loadingDocumentsFailed()];
          }
        }),
        tap(() => this.router.navigateByUrl('home/categories/list')),
        catchError(() => [policyDocumentsActions.loadingDocumentsFailed()])
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,

    private policyService: PolicyService,
    private router: Router
  ) { }
}
