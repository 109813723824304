import {enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';



import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TranslocoRootModule } from './app/transloco-root.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ProfileEffects } from './app/store/profile/profile.effects';
import { CompanyEffects } from './app/store/company-store/effects';
import { PolicyDocumentsEffects } from './app/store/policy/policy.document.effects';
import { AuthEffects } from './app/store/auth/auth.effects';
import { GeneralEffects } from './app/store/general/general-effects.service';
import { EffectsModule } from '@ngrx/effects';
import { profileCreateFeature } from './app/store/profile/profile.reducer';
import { companyCreateFeature } from './app/store/company-store/reducer';
import { policyDocumentFeature } from './app/store/policy/policy.document.reducer';
import { appReducers } from './app/store/app.state';
import { StoreModule } from '@ngrx/store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { TokenInterceptor } from './app/shared/services/token-interceptor.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule,
        MatProgressSpinnerModule, StoreModule.forRoot(appReducers, {}), StoreModule.forFeature(policyDocumentFeature), StoreModule.forFeature(companyCreateFeature), StoreModule.forFeature(profileCreateFeature), EffectsModule.forRoot([GeneralEffects, AuthEffects, PolicyDocumentsEffects, CompanyEffects, ProfileEffects]), StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }), TranslocoRootModule, HttpClientJsonpModule, ToastrModule.forRoot()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
      {
        provide: LOCALE_ID, useValue: 'it-IT'
      }
    ]
})
  .catch(err => console.error(err));
