const userClient = 'user_client';
const token = 'token';
const refreshToken = 'refreshToken';
const clientUrl = 'clients';
const company = 'company';


export const CONSTANTS = {
  userClient,
  token,
  clientUrl,
  company,
  refreshToken
};
