import { createReducer, on } from '@ngrx/store';
import { authActions } from './auth.actions';
// import {authActions} from './action-types';

export type AuthStatus =
  | 'INITIAL'
  | 'CHECKING'
  | 'AUTHENTICATING'
  | 'AUTHENTICATED'
  | 'UNAUTHENTICATED';

export interface AuthState {
  status: AuthStatus;
  token: string | undefined;
  refreshToken: string | undefined;

  // employee: EmployeeDto | undefined;

  // profileDipendenteCompleted: boolean;
  // profileCompletedToOfferPass: boolean;

  // client: Client | undefined;
  // company: CompanyOutputDto | undefined;
}

export const initialState: AuthState = {
  status: 'INITIAL',
  token: undefined,
  refreshToken: undefined,
};

export const authReducer = createReducer(
  initialState,
  on(
    authActions.checkingTokens,
    (state): AuthState => ({ ...state, status: 'CHECKING' })
  ),
  on(
    authActions.authenticatingStart,
    (state): AuthState => ({ ...state, status: 'AUTHENTICATING' })
  ),
  on(
    authActions.authenticatingSuccess,
    (state, action): AuthState => ({
      ...state,
      token: action.token,
      refreshToken: action.refreshToken,
      status: 'AUTHENTICATED',
    })
  ),
  on(
    authActions.refreshTokenEnd,
    (state, action): AuthState => ({
      ...state,
      token: action.token,
      refreshToken: action.refreshToken,
    })
  ),
  on(
    authActions.checkTokensFailed,
    (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
  ),
  on(authActions.loggingOut, (state): AuthState => ({ ...state })),
  on(authActions.checkSsoConfig, (state): AuthState => ({ ...state })),
  on(
    authActions.logout,
    (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
  ),
  on(
    authActions.logoutFailed,
    (state): AuthState => ({ ...state, status: 'UNAUTHENTICATED' })
  ),
  on(
    authActions.authenticatingByRealSSO,
    (state): AuthState => ({ ...state, status: 'AUTHENTICATING' })
  ),
  on(
    authActions.authenticatingByRealSSOSuccess,
    (state): AuthState => ({ ...state, status: 'CHECKING' })
  )
);
